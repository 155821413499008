(function () {
  'use strict';

  class ModuleSet {
    constructor(ModuleSetRest, $cookies, $rootScope) {
      this.ModuleSetRest = ModuleSetRest;
      this.$cookies = $cookies;
      this.$rootScope = $rootScope;
    }

    moduleSetIndex(moduleSet = {}, callback = angular.noop) {
      return this.ModuleSetRest.moduleSets().get(moduleSet,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    moduleSetCreate(moduleSet = {}, callback = angular.noop) {
      return this.ModuleSetRest.moduleSets().save({module_set: moduleSet},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    myModuleSetGet(moduleSet = {}, callback = angular.noop) {
      return this.ModuleSetRest.moduleSet().get(moduleSet,
        function (data) {
          const {module_set: {module_documents: {length}}} = data;
          data.module_set.has_documents = length > 0 ? true : false;
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    moduleDocuments(moduleSet = {}, callback = angular.noop) {
      return this.ModuleSetRest.moduleDocuments().get(moduleSet,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    moduleCategories(query = {}, callback = angular.noop) {
      return this.ModuleSetRest.moduleCategories().get(query,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    moduleSetDocuments(moduleSet = {}, callback = angular.noop) {
      return this.ModuleSetRest.moduleSetDocuments().get(moduleSet,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    myModuleSetUpdate(moduleSet = {}, callback = angular.noop) {
      return this.ModuleSetRest.moduleSet().update({module_set: moduleSet, id: moduleSet.id},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    myModuleSetClone(id, moduleSet = {}, callback = angular.noop) {
      moduleSet.id = id;
      return this.ModuleSetRest.moduleSetClone().save({module_set: moduleSet},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    myModuleSetDelete(moduleSet = {}, callback = angular.noop) {
      return this.ModuleSetRest.moduleSet().remove(moduleSet,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    myModuleSetUnlock(moduleSet = {}, callback = angular.noop) {
      return this.ModuleSetRest.unlock().save(moduleSet,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    myModuleSetGroupGet(moduleSet = {}, callback = angular.noop) {
      moduleSet.group = 'group';
      return this.ModuleSetRest.moduleSet().get(moduleSet,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    myModuleSetGroupCurrent(moduleSet = {}, callback = angular.noop) {
      moduleSet.group = 'group';
      moduleSet.current_module = 'current-module';
      return this.ModuleSetRest.moduleSet().save(moduleSet,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:ModuleSet
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('ModuleSet', ModuleSet);
}());
